import { Component, Inject, OnInit } from '@angular/core';
import { ApplicationService } from './service/application.service';
import { ApplicationDto } from './dto/application.dto.interface';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { frontofficeEnvironment } from '@shared/environment';
import { requestApplicationInformation } from './store/application/application.actions';
import { AppState } from './store/app.state';
import { select, Store } from '@ngrx/store';
import { selectApplication } from './store/application/application.selectors';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false,
})
export class AppComponent implements OnInit {
    favIcon: HTMLLinkElement = this.document.querySelector('#appIcon');
    head: HTMLElement = this.document.getElementsByTagName('head')[0];

    title = 'no-code-x-frontoffice-fe';

    public host: string = '';

    application$: Observable<ApplicationDto> = this.store.pipe(select(selectApplication));

    constructor(
        private applicationService: ApplicationService,
        private store: Store<AppState>,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit() {
        this.host = this.getHost(this.document.location);
        this.store.dispatch(requestApplicationInformation({ host: this.host, redirectToHome: true }));
    }

    private getHost(location: Location) {
        const host = location.hostname;
        if (host !== 'localhost') {
            if (host.indexOf('app.nocode-x.com') > -1) {
                return 'https://' + host.replace('app.nocode-x.com', 'back.nocode-x.com');
            } else {
                if (host.startsWith('dev-')) {
                    return 'https://' + 'dev-back-' + host.replace('dev-', '');
                } else if (host.startsWith('test-')) {
                    return 'https://' + 'test-back-' + +host.replace('test-', '');
                } else if (host.startsWith('accept-')) {
                    return 'https://' + 'accept-back-' + host.replace('accept-', '');
                } else {
                    return 'https://' + 'back-' + host;
                }
            }
        }
        return frontofficeEnvironment.localTestApp ? frontofficeEnvironment.localTestApp : null;
    }
}
