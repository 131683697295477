import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { bufferTime, catchError, map, mergeMap, tap } from 'rxjs/operators';
import { filter, Observable, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { backofficeEnvironment, frontofficeEnvironment } from '@shared/environment';
import {
    ActionExecutionCreatedDto,
    ActionExecutionFrontEndActionDto,
    ActionService,
    TemplateService,
    TemplateVersion,
    TemplateVersionResponse,
} from '@frontoffice/data-access/template';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { selectApplication } from '../application/application.selectors';
import {
    fetchBaseTemplateByHostSuccess,
    fetchBaseTemplateByIdSuccess,
    fetchTemplateByHostSuccess,
    fetchTemplateByIdSuccess,
    fetchTemplateError,
    requestChangeExternalAppTemplate,
    requestExecuteAction,
    requestExecuteActionError,
    requestExecuteActionSuccess,
    requestExecuteOnEnterAction,
    requestExecuteOnEntryActionsDummy,
    requestFetchTemplateByHost,
    requestFetchTemplateById,
    updateTemplateAfterActionSuccess,
    updateTemplateById,
    updateTemplateByPath,
} from './template.action';
import { templateBaseSelectors, templateByHostSelectors, templateByIdSelectors } from './template.selector';
import { TemplateFacade } from '../../../../../../libs/frontoffice/data-access/template/src/lib/facade/template.facade';
import { ExecutionScope } from '../../../../../../libs/frontoffice/data-access/template/src/lib/dto/interpretion/execution-scope';
import { createInvocationChain } from '../../../../../../libs/frontoffice/data-access/template/src/lib/dto/interpretion/invocation.dto';
import { MatDialogRef } from '@angular/material/dialog';
import { ApplicationDto } from '../../dto/application.dto.interface';
import { InterpretionService } from '../../../../../../libs/frontoffice/data-access/template/src/lib/service/interpretion.service';
import { TemplateArgument } from '../../../../../../libs/frontoffice/data-access/template/src/lib/models/template-argument.model';
import { GUIDFunctions } from '@shared/utils';

@Injectable()
export class TemplateEffects {
    dialogRefs: MatDialogRef<any>[] = [];

    private activeRequests = new Map<string, Observable<any>>();

    constructor(
        private templateService: TemplateService,
        private actionService: ActionService,
        private templateFacade: TemplateFacade,
        private interpretionService: InterpretionService,
        protected readonly actions$: Actions,
        @Inject(DOCUMENT) private document: Document,
        private readonly store: Store<AppState>
    ) {}

    private generateActionKey(trigger: string, actionId: string): string {
        return `${trigger}-${actionId}`;
    }

    sanitizeArguments(templateArguments: TemplateArgument[], returnedArguments: TemplateArgument[]) {
        this.sanitizeAllTemplateArguments(templateArguments, returnedArguments);
        return templateArguments;
    }

    sanitizeAllTemplateArguments(templateArguments: TemplateArgument[], returnedArguments: TemplateArgument[]) {
        templateArguments.forEach(templateArgument => {
            let indexOfArgumentWithMatchingSelectorButUnmatchingName = returnedArguments.findIndex(
                returnedArgument =>
                    returnedArgument.selectorId === templateArgument.selectorId && returnedArgument.name !== templateArgument.name
            );
            if (indexOfArgumentWithMatchingSelectorButUnmatchingName > -1) {
                templateArgument.name = returnedArguments[indexOfArgumentWithMatchingSelectorButUnmatchingName].name;
                if (templateArgument.subArguments && returnedArguments[indexOfArgumentWithMatchingSelectorButUnmatchingName].subArguments) {
                    //this.sanitizeAllTemplateArguments(templateArgument.subArguments, returnedArguments[indexOfArgumentWithMatchingSelectorButUnmatchingName].subArguments)
                }
            }
        });
    }

    requestFetchTemplateByHost$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestFetchTemplateByHost),
            concatLatestFrom(({ path, instanceIdentifier }) => [
                this.store.select(selectApplication),
                this.store.select(templateBaseSelectors.byId(path)),
            ]),
            mergeMap(([{ host, path, templateArguments, instanceIdentifier }, application, baseTemplateVersionResponse]) => {
                if (baseTemplateVersionResponse) {
                    const templateVersionResponse = Object.assign({}, baseTemplateVersionResponse);
                    templateArguments = this.sanitizeArguments(templateArguments, baseTemplateVersionResponse.templateVersion.arguments);
                    templateVersionResponse.templateVersion = Object.assign(
                        {},
                        JSON.parse(JSON.stringify(baseTemplateVersionResponse.templateVersion)),
                        {
                            arguments: templateArguments,
                            instanceIdentifier: instanceIdentifier,
                        }
                    );
                    templateVersionResponse.templateVersion.arguments = templateArguments;
                    templateVersionResponse.templateVersion.instanceIdentifier = instanceIdentifier;
                    return of(
                        fetchBaseTemplateByHostSuccess({
                            host,
                            templateVersionResponse: {
                                frontEndActions: templateVersionResponse.frontEndActions,
                                templateVersion: templateVersionResponse.templateVersion,
                                resetParts: true,
                                onEntryActions: templateVersionResponse.onEntryActions,
                            },
                        })
                    );
                } else {
                    return this.templateService
                        .getTemplateVersion(
                            host,
                            path,
                            application.applicationId,
                            application.companyId,
                            frontofficeEnvironment.onEntryOnBackend
                        )
                        .pipe(
                            map(baseTemplateVersionResponse => {
                                templateArguments = this.sanitizeArguments(
                                    templateArguments,
                                    baseTemplateVersionResponse.templateVersion.arguments
                                );
                                const templateVersionResponse = Object.assign({}, baseTemplateVersionResponse);
                                templateVersionResponse.templateVersion = Object.assign(
                                    {},
                                    JSON.parse(JSON.stringify(baseTemplateVersionResponse.templateVersion)),
                                    {
                                        arguments: templateArguments,
                                        instanceIdentifier: instanceIdentifier,
                                        baseIdentifier: path,
                                    }
                                );
                                return templateVersionResponse;
                            }),
                            map(templateVersionResponse =>
                                fetchBaseTemplateByHostSuccess({
                                    host,
                                    templateVersionResponse,
                                })
                            ),
                            catchError(() => of(fetchTemplateError()))
                        );
                }
            })
        )
    );

    requestFetchTemplateById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestFetchTemplateById),
            concatLatestFrom(({ templateId }) => [
                this.store.select(selectApplication),
                this.store.select(templateBaseSelectors.byId(templateId)),
            ]),
            mergeMap(
                ([
                    { templateId, host, path, templateArguments, instanceIdentifier, languageCode, webComponent },
                    application,
                    baseTemplateVersionResponse,
                ]) => {
                    if (baseTemplateVersionResponse) {
                        const templateVersionResponse = Object.assign({}, baseTemplateVersionResponse);
                        templateArguments = this.sanitizeArguments(
                            templateArguments,
                            baseTemplateVersionResponse.templateVersion.arguments
                        );
                        templateVersionResponse.templateVersion = Object.assign(
                            {},
                            JSON.parse(JSON.stringify(baseTemplateVersionResponse.templateVersion)),
                            {
                                arguments: templateArguments,
                                instanceIdentifier: instanceIdentifier,
                            }
                        );
                        templateVersionResponse.templateVersion.arguments = templateArguments;
                        templateVersionResponse.templateVersion.instanceIdentifier = instanceIdentifier;
                        return of(
                            fetchBaseTemplateByIdSuccess({
                                host,
                                templateVersionResponse: {
                                    frontEndActions: templateVersionResponse.frontEndActions,
                                    templateVersion: templateVersionResponse.templateVersion,
                                    resetParts: true,
                                    onEntryActions: templateVersionResponse.onEntryActions,
                                },
                                webComponent,
                            })
                        );
                    } else {
                        return this.templateService
                            .getTemplateVersionByIdAndArgumentsAndParams(
                                host,
                                templateId,
                                application.applicationId,
                                application.companyId,
                                templateArguments,
                                null,
                                true,
                                false,
                                languageCode
                            )
                            .pipe(
                                map(baseTemplateVersionResponse => {
                                    const templateVersionResponse = Object.assign({}, baseTemplateVersionResponse);
                                    templateArguments = this.sanitizeArguments(
                                        templateArguments,
                                        baseTemplateVersionResponse.templateVersion.arguments
                                    );
                                    templateVersionResponse.templateVersion = Object.assign(
                                        {},
                                        JSON.parse(JSON.stringify(baseTemplateVersionResponse.templateVersion)),
                                        {
                                            arguments: templateArguments,
                                            instanceIdentifier: instanceIdentifier,
                                            baseIdentifier: templateId,
                                        }
                                    );
                                    return templateVersionResponse;
                                }),
                                map(templateVersionResponse =>
                                    fetchBaseTemplateByIdSuccess({ host, templateVersionResponse, webComponent })
                                ),
                                catchError(() => of(fetchTemplateError()))
                            );
                    }
                }
            )
        )
    );

    requestChangeExternalAppTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestChangeExternalAppTemplate),
            mergeMap(({ templateId, templateArguments, instanceIdentifier, path, host }) => {
                return of(
                    requestFetchTemplateById({
                        templateId,
                        path,
                        host,
                        templateArguments,
                        instanceIdentifier,
                        webComponent: true,
                    })
                );
            })
        )
    );

    requestOnEntryActionsById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchBaseTemplateByIdSuccess, requestExecuteOnEnterAction),
            concatLatestFrom(() => [this.store.select(selectApplication)]),
            mergeMap(([{ host, templateVersionResponse, webComponent }, application], index) => {
                if (templateVersionResponse.onEntryActions && templateVersionResponse.onEntryActions.length > 0) {
                    return of(
                        requestExecuteAction(
                            this.templateFacade.prepareRequestExecuteAction(
                                host,
                                templateVersionResponse.templateVersion.templateDto.id,
                                'ON_ENTER',
                                templateVersionResponse.onEntryActions,
                                templateVersionResponse.templateVersion.instanceIdentifier,
                                templateVersionResponse.templateVersion.arguments,
                                null,
                                false,
                                null,
                                null,
                                webComponent,
                                templateVersionResponse
                            )
                        )
                    );
                } else {
                    return of(
                        requestExecuteOnEntryActionsDummy({
                            templateVersionResponse: templateVersionResponse,
                            byPath: false,
                        })
                    );
                }
            })
        )
    );

    requestOnEntryActionsByHost$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchBaseTemplateByHostSuccess),
            concatLatestFrom(() => [this.store.select(selectApplication)]),
            mergeMap(([{ host, templateVersionResponse }, application], index) => {
                if (templateVersionResponse.onEntryActions && templateVersionResponse.onEntryActions.length > 0) {
                    return of(
                        requestExecuteAction(
                            this.templateFacade.prepareRequestExecuteAction(
                                host,
                                templateVersionResponse.templateVersion.templateDto.id,
                                'ON_ENTER',
                                templateVersionResponse.onEntryActions,
                                templateVersionResponse.templateVersion.instanceIdentifier,
                                templateVersionResponse.templateVersion.arguments,
                                null,
                                true,
                                null,
                                null,
                                false,
                                templateVersionResponse
                            )
                        )
                    );
                } else {
                    return of(
                        requestExecuteOnEntryActionsDummy({
                            templateVersionResponse: templateVersionResponse,
                            byPath: true,
                        })
                    );
                }
            })
        )
    );

    slugifyPath() {
        const path = location.pathname.slice(1).replace(/\//g, '-');
        const searchParams = new URLSearchParams(location.search);
        const queryParts: string[] = [];

        const sanitize = (input: string): string => input.replace(/[^a-zA-Z0-9-_:]/g, '-'); // Rep

        searchParams.forEach((value, key) => {
            queryParts.push(`${sanitize(key)}-${sanitize(value)}`);
        });

        const queryString = queryParts.join('-');
        return queryString ? `${path}-${queryString}` : path;
    }

    requestExecuteActionMulti$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestExecuteAction),
            filter(() => backofficeEnvironment.multiActionExecution),
            concatLatestFrom(({ templateId, byPath, formTemplateIdentifier, webComponent, templateVersionResponse }) => {
                let templateVersionObservable: Observable<TemplateVersionResponse>;
                if (templateVersionResponse) {
                    templateVersionObservable = of(templateVersionResponse);
                } else {
                    if (byPath) {
                        templateVersionObservable = this.store.select(templateByHostSelectors.byId(this.slugifyPath()));
                    } else {
                        templateVersionObservable = this.store.select(templateByIdSelectors.byId(templateId));
                    }
                }
                return [
                    this.store.select(selectApplication),
                    templateVersionObservable,
                    formTemplateIdentifier && this.store.select(templateByHostSelectors.byId(formTemplateIdentifier))
                        ? this.store.select(templateByHostSelectors.byId(formTemplateIdentifier))
                        : this.store.select(templateByIdSelectors.byId(templateId)),
                ];
            }),
            map(
                ([
                    {
                        host,
                        trigger,
                        triggerType,
                        actionIds,
                        templateId,
                        templateArguments,
                        executionResultPartId,
                        byPath,
                        formGroup,
                        webComponent,
                        formTemplateIdentifier,
                    },
                    application,
                    templateVersionResponse,
                    formTemplateVersionResponse,
                ]) => {
                    return {
                        host,
                        trigger,
                        triggerType,
                        actionIds,
                        templateId,
                        templateArguments,
                        executionResultPartId,
                        byPath,
                        formGroup,
                        webComponent,
                        formTemplateIdentifier,
                        application,
                        templateVersionResponse,
                        formTemplateVersionResponse,
                    };
                }
            ),
            bufferTime(150),
            mergeMap(
                (
                    actions: {
                        host;
                        trigger;
                        triggerType;
                        actionIds;
                        templateId;
                        templateArguments;
                        executionResultPartId;
                        byPath;
                        formGroup;
                        webComponent;
                        formTemplateIdentifier;
                        application;
                        templateVersionResponse;
                        formTemplateVersionResponse;
                        actionExecutionId;
                    }[]
                ) => {
                    if (actions.length === 0) {
                        return of(); // If no actions were collected, return empty observable
                    }
                    return this.actionService
                        .createActionMultiExecution(
                            actions[0].host,
                            actions[0].application.companyId,
                            actions.map(action => {
                                action.actionExecutionId = new GUIDFunctions().newGuid();
                                return {
                                    actionExecutionId: action.actionExecutionId,
                                    trigger: action.trigger,
                                    triggerType: action.triggerType,
                                    actionIds: action.actionIds,
                                    templateVersion: TemplateVersion.prepareForAction(action.templateVersionResponse.templateVersion),
                                    applicationId: action.application.applicationId,
                                    companyId: action.application.companyId,
                                    arguments: action.templateArguments,
                                    currentUrl: {
                                        path: window.location.pathname,
                                        domain: window.location.host,
                                    },
                                    browser: {
                                        language: navigator.language,
                                        online: navigator.onLine,
                                        userAgent: navigator.userAgent,
                                    },
                                    formResults: this.templateFacade.createFormResults(action.formGroup),
                                };
                            })
                        )
                        .pipe(
                            mergeMap(actionExecutionsCreated => {
                                // We need to reverse this because we want the
                                const actionExecutionsCreatedReversed = actionExecutionsCreated.reverse();
                                return actionExecutionsCreatedReversed.map(actionExecutionCreated => {
                                    let action = actions.find(
                                        action => action.actionExecutionId === actionExecutionCreated.actionExecutionId
                                    );
                                    actionExecutionCreated.templateVersion.instanceIdentifier = action.templateId;
                                    return requestExecuteActionSuccess({
                                        actionExecutionCreated,
                                        byPath: action.byPath,
                                        triggerType: action.triggerType,
                                        host: action.host,
                                        executionResultPartId: action.executionResultPartId,
                                        webComponent: action.webComponent,
                                    });
                                });
                            }),
                            catchError(() => {
                                return of(requestExecuteActionError());
                            })
                        );
                }
            )
        )
    );

    updateTemplateAfterNoOnEntryAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestExecuteOnEntryActionsDummy),
            concatLatestFrom(({ templateVersionResponse, byPath }) => [this.store.select(selectApplication)]),
            mergeMap(([{ templateVersionResponse, byPath }]) => {
                if (byPath) {
                    return of(fetchTemplateByHostSuccess({ templateVersionResponse: templateVersionResponse }));
                } else {
                    return of(fetchTemplateByIdSuccess({ templateVersionResponse: templateVersionResponse }));
                }
            })
        )
    );

    updateTemplateAfterOnEntryAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestExecuteActionSuccess),
            filter(({ triggerType }) => triggerType === 'ON_ENTER'),
            concatLatestFrom(({ actionExecutionCreated }) => [
                this.store.select(selectApplication),
                this.store.select(templateBaseSelectors.byId(actionExecutionCreated.templateVersion.instanceIdentifier)),
            ]),
            mergeMap(([{ actionExecutionCreated, byPath, host, executionResultPartId, webComponent }, application, baseTemplate]) => {
                this.executeFrontEndActions(actionExecutionCreated, application, host, executionResultPartId, webComponent, byPath);
                if (byPath) {
                    return of(
                        fetchTemplateByHostSuccess({
                            templateVersionResponse: Object.assign({}, baseTemplate, {
                                templateVersion: actionExecutionCreated.templateVersion,
                            }),
                        })
                    );
                } else {
                    return of(
                        fetchTemplateByIdSuccess({
                            templateVersionResponse: Object.assign({}, baseTemplate, {
                                templateVersion: actionExecutionCreated.templateVersion,
                            }),
                        })
                    );
                }
            })
        )
    );

    updateTemplateAfterAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestExecuteActionSuccess),
            filter(({ triggerType }) => !triggerType || triggerType !== 'ON_ENTER'),
            concatLatestFrom(() => [this.store.select(selectApplication)]),
            mergeMap(([{ actionExecutionCreated, byPath, host, executionResultPartId, webComponent }, application]) => {
                this.executeFrontEndActions(actionExecutionCreated, application, host, executionResultPartId, webComponent, byPath);
                if (this.shouldReloadTemplate(actionExecutionCreated.frontEndActions)) {
                    if (!!executionResultPartId) {
                        actionExecutionCreated.templateVersion.instanceIdentifier = executionResultPartId;
                        return of(
                            updateTemplateById({
                                templateVersion: actionExecutionCreated.templateVersion,
                            })
                        );
                    } else {
                        if (byPath) {
                            return of(updateTemplateByPath({ templateVersion: actionExecutionCreated.templateVersion }));
                        } else {
                            return of(updateTemplateById({ templateVersion: actionExecutionCreated.templateVersion }));
                        }
                    }
                } else {
                    return of(updateTemplateAfterActionSuccess());
                }
            })
        )
    );

    executeFrontEndActions(
        actionExecutionCreated: ActionExecutionCreatedDto,
        application: ApplicationDto,
        host: string,
        executionResultPartId: string,
        webComponent: boolean,
        byPath: boolean
    ) {
        if (actionExecutionCreated.frontEndActions && actionExecutionCreated.frontEndActions.length > 0) {
            const scope: ExecutionScope = new ExecutionScope();
            scope.createInitialScope(
                actionExecutionCreated.templateVersion,
                application,
                host,
                this.dialogRefs,
                (result: ActionExecutionCreatedDto, $event?: { executionResultPartId?: string; triggerType?: string }) => {
                    this.store.dispatch(
                        requestExecuteActionSuccess({
                            actionExecutionCreated: result,
                            byPath: byPath,
                            host,
                            triggerType: $event.triggerType,
                            executionResultPartId: $event.executionResultPartId,
                        })
                    );
                },
                executionResultPartId,
                webComponent
            );
            const invocations = createInvocationChain(actionExecutionCreated.frontEndActions);
            this.interpretionService.interprete(invocations, scope);
            this.dialogRefs = scope.getRequiredVariable('dialogRefs');
        }
    }

    shouldReloadTemplate(frontEndActions: ActionExecutionFrontEndActionDto[]): boolean {
        const dontReloadTemplateAction = frontEndActions.find(
            frontEndAction => frontEndAction.type === 'dontreloadtemplate' || frontEndAction.type === 'route'
        );
        return !dontReloadTemplateAction;
    }
}
