import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, debounceTime, from, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApplicationDto } from '../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { replaceArgumentsForString } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/part-arguments';
import { TemplateArgument } from '../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

declare let jQuery: any;
@Component({
    selector: 'template-part-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss'],
    standalone: false,
})
export class TemplateComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input()
    template: TemplateVersion;

    @Input()
    application: ApplicationDto;

    @Input()
    host: string;

    @Input()
    parentFormGroup: FormGroup;

    @Input()
    formGroupId: string;

    @Input()
    identifier: string;

    partFormGroup: FormGroup;

    @Output()
    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }> = new EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>();

    @Output()
    templateResult: EventEmitter<{ id: string; value: string; name: string }> = new EventEmitter<{
        id: string;
        value: string;
        name: string;
    }>();

    // we keep a map of subscriptions because:
    // we have to create a subscription for each inner form part of the template.
    // this in order to receive updates on those parts and gather them as "template results"
    // Because we do not want to keep unecessary subscriptions open we want to unsubscribe as fast as possible
    // In fact every time we "initializeTemplateResultSubscriptions" we need to unsubscribe to any subscription that was still running
    // And create a whole new set of subscriptions, this happens onchange of the template
    // in other words everytime an action is executed.
    partSubscriptions: Map<string, Subscription> = new Map<string, Subscription>();

    resizeObserver: ResizeObserver;

    mutationObserver: MutationObserver;

    pollingInterval: any;

    subscriptions: Subscription = new Subscription();

    private resizePart: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.initializePartFormGroup();
        this.initializeTemplateResultSubscriptions();
        this.subscriptions.add(
            this.resizePart.pipe(debounceTime(20)).subscribe(resizeNumber => {
                this.adjustHeightOfSubContainer();
            })
        );
    }

    ngOnChanges(): void {
        this.initializeTemplateResultSubscriptions();
    }

    ngOnDestroy(): void {
        this.partSubscriptions.forEach((value: Subscription) => value.unsubscribe());
        this.subscriptions.unsubscribe();
        this.parentFormGroup.removeControl(this.identifier);
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
        }
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
        }
    }

    ngAfterViewInit() {
        this.initResizeObservers();
    }

    initResizeObservers() {
        const element = document.querySelector('#' + this.identifier + ' > .part-container');
        if (element) {
            this.resizeObserver = new ResizeObserver(entries => {
                this.resizePart.next(this.resizePart.value + 1);
            });
            this.resizeObserver.observe(element);

            this.mutationObserver = new MutationObserver(() => {
                this.resizePart.next(this.resizePart.value + 1);
            });
            this.mutationObserver.observe(element, {
                childList: true,
                attributes: false,
                subtree: true,
            });

            // Polling as a fallback mechanism
            this.pollingInterval = setInterval(() => {
                this.resizePart.next(this.resizePart.value + 1);
            }, 100); // Adjust the interval as needed
        }
    }

    setParentHeight(parent: any, value: string) {
        if (parent.css('height') !== value) {
            parent.height(value);
        }
    }

    setParentWidth(parent: any, value: string) {
        if (parent.css('width') !== value) {
            parent.width(value);
        }
    }

    adjustHeightOfSubContainer(): void {
        const parent = jQuery('#' + this.identifier + ' > .part-container');
        let listItems = null;
        if (this.template.sizeYUnit === 'fit-content' || this.template.sizeXUnit === 'fit-content') {
            listItems = jQuery('#' + this.identifier + ' > .part-container > part');
        }

        if (this.template.sizeYUnit === 'fit-content') {
            let highestHeight = 0;
            for (let i = 0; i < listItems.length; i++) {
                const content = listItems.eq(i);
                const height = content.get(0).getBoundingClientRect().height;
                const top: number = content.get(0).getBoundingClientRect().top - parent.get(0).getBoundingClientRect().top;
                if (height + top > highestHeight) {
                    highestHeight = height + top;
                }
            }
            this.setParentHeight(parent, Math.round(highestHeight) + 'px');
        }

        if (this.template.sizeXUnit === 'fit-content') {
            let highestWidth = 0;
            for (let i = 0; i < listItems.length; i++) {
                const content = listItems.eq(i);
                const width = content.get(0).getBoundingClientRect().width;
                const left: number = content.get(0).getBoundingClientRect().left - parent.get(0).getBoundingClientRect().left;
                if (width + left > highestWidth) {
                    highestWidth = width + left;
                }
            }
            this.setParentWidth(parent, Math.round(highestWidth) + 'px');
        }
    }

    initializePartFormGroup(): void {
        this.partFormGroup = this.fb.group({
            type: ['template'],
        });
        if (this.formGroupId && this.formGroupId !== '') {
            let formGroup = this.parentFormGroup.get(this.formGroupId) as FormGroup;
            if (formGroup == null) {
                formGroup = this.fb.group({
                    type: ['group'],
                    code: [this.formGroupId],
                });
                this.parentFormGroup.addControl(this.formGroupId, formGroup);
            }
            if (!formGroup.get(this.identifier)) {
                formGroup.addControl(this.identifier, this.partFormGroup);
            }
        } else {
            if (!this.parentFormGroup.get(this.identifier)) {
                this.parentFormGroup.addControl(this.identifier, this.partFormGroup);
            }
        }
    }

    initializeTemplateResultSubscriptionsForParts(parts: Part[] | undefined): void {
        if (parts && !!this.partFormGroup) {
            const partsToCheck = [];
            parts.forEach(part => {
                if (Part.isFormComponent(part)) {
                    if (part.detail['code']) {
                        partsToCheck.push(part);
                        if (this.partSubscriptions.has(part.detail['code'])) {
                            this.partSubscriptions.get(part.detail['code'])?.unsubscribe();
                        }
                        this.partSubscriptions.set(part.detail['code'], new Subscription());
                        this.partSubscriptions.get(part.detail['code'])?.add(
                            from(replaceArgumentsForString(part.detail['answer'], this.template.arguments))
                                .pipe(take(1))
                                .subscribe(result => {
                                    this.templateResult.emit({
                                        id: part.detail['code'],
                                        value: result,
                                        name: part.detail['code'],
                                    });
                                })
                        );
                    }
                }
            });

            this.subscriptions.add(
                this.partFormGroup.valueChanges.subscribe(() => {
                    partsToCheck.forEach(partToCheck => {
                        if (!!this.partFormGroup && !!this.partFormGroup.get(partToCheck.instanceIdentifier)) {
                            if (partToCheck.detail['code']) {
                                const templateResult = {
                                    id: partToCheck.detail['code'],
                                    // Check if value is FormGroup (Components of TYPE Formfield store all information in a "formgroup")
                                    value:
                                        this.partFormGroup.get(partToCheck.instanceIdentifier)?.value instanceof Object
                                            ? this.partFormGroup.get(partToCheck.instanceIdentifier)?.value.value
                                            : this.partFormGroup.get(partToCheck.instanceIdentifier)?.value,
                                    name: partToCheck.detail['code'],
                                };
                                this.templateResult.emit(templateResult);
                            }
                        }
                    });
                })
            );
        }
    }

    initializeTemplateResultSubscriptions(): void {
        this.changeDetectorRef.detectChanges();
        this.initializeTemplateResultSubscriptionsForParts(this.template?.parts);
    }

    identifyPart(index, item): string {
        return this.identifier + item.selectorId;
    }
}
