import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagePartDetail, replaceArguments } from '../../model/image-part.detail';
import { ImagePartStyle } from '../../model/image-part.style';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

declare let jQuery: any;

@Component({
    selector: 'app-image-part-front',
    templateUrl: './image-part-front.component.html',
    styleUrls: ['./image-part-front.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ImagePartFrontComponent implements ComponentFront, OnInit, AfterViewInit, OnChanges {
    partDetail: ImagePartDetail = null;
    partStyle: ImagePartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    host = '';

    @ViewChild('urlImageContainer') urlImageContainer: ElementRef;
    @ViewChild('svgContainer') svgContainer: ElementRef;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public sanitizer: DomSanitizer
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        replaceArguments(this.partDetail, this.templateVersion.arguments).then(() => {
            if (this.partDetail.linkType === 'svg' && !!this.partDetail.processedSvg) {
                if (this.svgContainer && this.svgContainer.nativeElement) {
                    this.svgContainer.nativeElement.innerHTML = this.partDetail.processedSvg;
                }
            } else if (this.partDetail.linkType === 'url' && !!this.partDetail.processedUrl) {
                if (this.urlImageContainer && this.urlImageContainer.nativeElement) {
                    this.urlImageContainer.nativeElement.src = this.partDetail.processedUrl;
                }
            }
        });
        this.handleAutomaticResizing();
    }

    ngOnChanges(changes: SimpleChanges): void {
        replaceArguments(this.partDetail, this.templateVersion.arguments).then(() => {
            if (this.partDetail.linkType === 'svg' && !!this.partDetail.processedSvg) {
                if (this.svgContainer && this.svgContainer.nativeElement) {
                    this.svgContainer.nativeElement.innerHTML = this.partDetail.processedSvg;
                }
            } else if (this.partDetail.linkType === 'url' && !!this.partDetail.processedUrl) {
                if (this.urlImageContainer && this.urlImageContainer.nativeElement) {
                    this.urlImageContainer.nativeElement.src = this.partDetail.processedUrl;
                }
            }
        });
    }

    handleAutomaticResizing() {
        if (this.partDetail.automaticResizing) {
            let width = jQuery('#id-' + this.part.id).width();
            let height = jQuery('#id-' + this.part.id).height();
            if (jQuery('#id-' + this.part.id).css('object-fit') === 'cover') {
                width = width * 2;
                height = height * 2;
            }
            jQuery('#id-' + this.part.id).attr(
                'src',
                this.host +
                    '/cdn-cgi/image/width=' +
                    width +
                    ',height=' +
                    height +
                    ',fit=scale-down,quality=100,format=auto/v2/media/file/' +
                    this.partDetail.mediaId
            );
        }
    }

    executeOnClickAction(): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }
}
