import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { FormGroup } from '@angular/forms';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { OtpQrPartDetail } from '../../model/otp-qr-part.detail';
import { OtpQrPartStyle } from '../../model/otp-qr-part.style';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-otp-qr-part-front',
    templateUrl: './otp-qr-part-front.component.html',
    styleUrls: ['./otp-qr-part-front.component.scss'],
    standalone: false,
})
export class OtpQrPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: OtpQrPartDetail = null;
    partStyle: OtpQrPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {}
}
