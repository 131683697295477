import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { FormGroup } from '@angular/forms';
import { IconButtonPartDetail, replaceArguments } from '../../model/icon-button-part.detail';
import { IconButtonPartStyle } from '../../model/icon-button-part.style';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-icon-button-part-front',
    templateUrl: './icon-button-part-front.component.html',
    styleUrls: ['./icon-button-part-front.component.scss'],
    standalone: false,
})
export class IconButtonPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: IconButtonPartDetail = null;
    partStyle: IconButtonPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    ngOnChanges(changes: SimpleChanges): void {
        replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    executeOnClickAction() {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    onClick() {
        if (this.partDetail.type === 'submit') {
            this.parentFormGroup.markAllAsTouched();
            if (this.parentFormGroup.valid) {
                this.executeOnClickAction();
            }
        } else {
            this.executeOnClickAction();
        }
    }
}
