<div *ngIf="options && uploadInput" [id]="'id-' + part.id" class="part-content file-uploader">
    <div *ngIf="this.files">
        <div *ngFor="let file of this.files" class="file">{{ file.name }}</div>
    </div>

    <div
        class="drop-container"
        ngFileDrop
        [options]="options"
        (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput"
        [ngClass]="{
            'is-drop-over': dragOver,
            hidden: !this.partDetail.showDropContainer || this.files.length >= this.partDetail.maxUploads,
        }">
        <div style="margin-bottom: 5px">{{ partDetail.dragAndDropText }}</div>
        <div *ngIf="this.partDetail.showPickFileButton && this.files.length < this.partDetail.maxUploads">
            <label class="upload-button">
                <input
                    [id]="this.part.id + 'files'"
                    type="file"
                    ngFileSelect
                    [options]="options"
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput"
                    multiple />
                {{ partDetail.pickFileButtonText }}
            </label>
        </div>
    </div>

    <button
        *ngIf="this.partDetail.showUploadButton && this.files.length > 0 && this.files.length <= this.partDetail.maxUploads"
        type="button"
        (click)="startUpload()"
        color="primary"
        mat-button
        mat-raised-button>
        {{ partDetail.startUploadButtonText }}
    </button>
</div>
