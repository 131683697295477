<div *ngIf="partDetail" class="w-full h-full part-content">
    <button
        class="w-full !h-full"
        mat-raised-button
        [id]="'id-' + part.id"
        preventDoubleClick
        [disabledByPart]="!partDetail.enabled"
        (click)="onClick()"
        (keyup.enter)="onClick()"
        [disabled]="!partDetail.enabled"
        [matTooltip]="partDetail.processedTooltip">
        <mat-icon *ngIf="partDetail.icon && (!partDetail.iconPosition || partDetail.iconPosition === 'left')">{{
            partDetail.processedIcon
        }}</mat-icon>
        {{ partDetail.processedLabel }}
        <mat-icon iconPositionEnd *ngIf="partDetail.icon && partDetail.iconPosition === 'right'">{{ partDetail.processedIcon }}</mat-icon>
    </button>
</div>
