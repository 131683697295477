import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActionService, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../dto/application.dto.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PartActionLink } from '../../model/part-action-link.model';
import { BehaviorSubject, debounceTime, Subscription } from 'rxjs';
import { TemplateArgument } from '../../../../../../../libs/frontoffice/data-access/template/src/lib/models/template-argument.model';

declare let jQuery: any;

@Component({
    selector: 'app-template',
    styleUrls: ['./template.component.scss'],
    templateUrl: './template.component.html',
    standalone: false,
})
export class TemplateComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    template: TemplateVersion;

    @Input()
    application: ApplicationDto;

    @Input()
    templateIdentifier: string;

    @Input()
    host: string;

    @Output()
    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
        templateVersion?: TemplateVersion;
    }> = new EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
        templateVersion?: TemplateVersion;
    }>();

    @Input()
    parentFormGroup: FormGroup;

    subscriptions: Subscription = new Subscription();

    resizeObserver: ResizeObserver;

    mutationObserver: MutationObserver;

    private resizePart: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        public actionService: ActionService,
        public fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.resizePart.pipe(debounceTime(20)).subscribe(() => {
                this.adjustHeightOfSubContainer();
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
        }
    }

    ngAfterViewInit() {
        this.initResizeObservers();
    }

    initResizeObservers() {
        const element = document.querySelector('#template-' + this.template?.templateDto.id);
        if (element) {
            this.resizeObserver = new ResizeObserver(entries => {
                this.resizePart.next(this.resizePart.value + 1);
            });
            this.resizeObserver.observe(element);

            this.mutationObserver = new MutationObserver(() => {
                this.resizePart.next(this.resizePart.value + 1);
            });
            this.mutationObserver.observe(element, {
                childList: true,
                attributes: false,
                subtree: true,
            });
        }
    }

    setParentHeight(parent: any, value: string) {
        if (parent.css('height') !== value) {
            parent.height(value);
        }
    }

    setParentWidth(parent: any, value: string) {
        if (parent.css('width') !== value) {
            parent.width(value);
        }
    }
    adjustHeightOfSubContainer(): void {
        const parent = jQuery('#template-' + this.template?.templateDto.id);
        let listItems = null;
        if (this.template.sizeYUnit === 'fit-content' || this.template.sizeXUnit === 'fit-content') {
            listItems = jQuery('#template-' + this.template?.templateDto.id + ' > part');
            if (this.template.sizeYUnit === 'fit-content') {
                this.setParentHeight(parent, 'unset');
            } else {
                this.setParentHeight(parent, TemplateVersion.getHeight(this.template));
            }
            if (this.template.sizeXUnit === 'fit-content') {
                this.setParentHeight(parent, 'unset');
            } else {
                this.setParentWidth(parent, TemplateVersion.getWidth(this.template));
            }
        } else {
            this.setParentHeight(parent, TemplateVersion.getHeight(this.template));
            this.setParentWidth(parent, TemplateVersion.getWidth(this.template));
        }

        if (this.template.sizeYUnit === 'fit-content') {
            let highestHeight = 0;
            for (let i = 0; i < listItems.length; i++) {
                const content = listItems.eq(i);
                const height = content.get(0).getBoundingClientRect().height;
                const top: number = content.get(0).getBoundingClientRect().top - parent.get(0).getBoundingClientRect().top;
                if (height + top > highestHeight) {
                    highestHeight = height + top;
                }
            }
            this.setParentHeight(parent, Math.round(highestHeight) + 'px');
        }

        if (this.template.sizeXUnit === 'fit-content') {
            let highestWidth = 0;
            for (let i = 0; i < listItems.length; i++) {
                const content = listItems.eq(i);
                const width = content.get(0).getBoundingClientRect().width;
                const left: number = content.get(0).getBoundingClientRect().left - parent.get(0).getBoundingClientRect().left;
                if (width + left > highestWidth) {
                    highestWidth = width + left;
                }
            }
            this.setParentWidth(parent, Math.round(highestWidth) + 'px');
        }
    }

    generateTemplateStyle() {
        const style = '';
        if (this.template.style) {
            if (this.template.style.backgroundColor) {
                document.body.style.backgroundColor = this.template.style.backgroundColor;
            }
            if (this.template.style.backgroundImage) {
                document.body.style.backgroundImage = 'url("' + this.template.style.backgroundImage + '")';
            }
            if (this.template.style.font) {
                document.body.style.fontFamily = this.template.style.font;
            }
            if (this.template.style.fontSize) {
                document.body.style.fontSize = this.template.style.fontSize + 'px';
            }
            if (this.template.style.fontColor) {
                document.body.style.color = this.template.style.fontColor;
            }
            if (this.template.style.fontWeight) {
                document.body.style.fontWeight = this.template.style.fontWeight;
            }
        }
        return style;
    }

    identifyPart(index, item): string {
        // Adding templateID to identify this part is a very hacky way to make sure template parts should be refreshed if
        // They have the same selectorId but a different template configured.
        // This can happen when a user copies a page & then changes the template in the copied page.
        // Without templateID in this return value, hopping from the copied page to the original page & back will not result in changing the content of
        // the template component.
        return item.selectorId + item.detail['templateId'];
    }
}
