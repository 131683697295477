import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { FormGroup } from '@angular/forms';
import { HtmlPartDetail, LinkDto, replaceArguments } from '../../model/html-part.detail';
import { HtmlPartStyle } from '../../model/html-part.style';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, from, switchMap } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

declare var jQuery: any;

@Component({
    selector: 'app-html-part-front',
    templateUrl: './html-part-front.component.html',
    styleUrls: ['./html-part-front.component.scss'],
    standalone: false,
})
export class HtmlPartFrontComponent implements ComponentFront, OnInit, OnChanges, OnDestroy, AfterViewInit {
    partDetail: HtmlPartDetail = null;
    partStyle: HtmlPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    loadedPreJavascriptUrls: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    loadedScripts: Map<string, HTMLScriptElement> = new Map();

    htmlAdded: boolean = false;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public sanitizer: DomSanitizer
    ) {}

    parentFormGroup: FormGroup<any>;

    ngOnInit(): void {
        window[this.part?.id] = window[this.part?.id] || {};
        window[this.part?.id].actions = window[this.part?.id].actions || {};
        window[this.part?.id].actions.executeOnEventAction = this.executeOnEventAction.bind(this);
    }

    ngAfterViewInit() {
        this.loadElement();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.loadOnChangedElements();
    }

    ngOnDestroy(): void {}

    updateArguments(_arguments: TemplateArgument[]) {
        _arguments.push({
            name: 'PART_WIDTH',
            calculatedValue: jQuery('#part-' + this.part.id).width(),
            value: jQuery('#part-' + this.part.id).width(),
        });
        _arguments.push({
            name: 'PART_HEIGHT',
            calculatedValue: jQuery('#part-' + this.part.id).height(),
            value: jQuery('#part-' + this.part.id).height(),
        });
    }

    loadOnChangedElements() {
        this.updateArguments(this.templateVersion.arguments);

        if (this.partDetail?.cssUrls && this.partDetail?.cssUrls.length > 0) {
            this.partDetail?.cssUrls.forEach(cssUrl => {
                this.loadCssUrl(cssUrl);
            });
        }
        this.loadCss(this.partDetail?.css);
        this.loadedPreJavascriptUrls
            .pipe(
                filter(loadedUrls => this.partDetail.javascriptUrls && loadedUrls.length === this.partDetail.javascriptUrls.length),
                take(1),
                switchMap(() => replaceArguments(this.partDetail, this.templateVersion.arguments))
            )
            .subscribe(loadedUrls => {
                this.loadHtml(this.partDetail?.processedHtml);
                if (this.partDetail.processedPreJavascriptSuccess) {
                    this.loadScript('pre-javascript', this.partDetail?.processedPreJavascript);
                }
                if (this.partDetail.processedPostJavascriptSuccess) {
                    eval(this.surroundWithTryCatch(this.partDetail?.processedPostJavascript));
                }
            });
    }

    surroundWithTryCatch(javascriptContent: string) {
        let content = 'try {';
        content += javascriptContent;
        content += '} catch (err) {';
        content += "console.log('error occured', err);";
        content += '}';
        return content;
    }

    loadElement() {
        this.updateArguments(this.templateVersion.arguments);

        if (this.partDetail?.cssUrls && this.partDetail?.cssUrls.length > 0) {
            this.partDetail?.cssUrls.forEach(cssUrl => {
                this.loadCssUrl(cssUrl);
            });
        }

        this.loadCss(this.partDetail?.css);
        if (this.partDetail?.javascriptUrls && this.partDetail?.javascriptUrls.length > 0) {
            this.loadedPreJavascriptUrls
                .pipe(
                    filter(loadedUrls => this.partDetail.javascriptUrls && loadedUrls.length === this.partDetail.javascriptUrls.length),
                    switchMap(() => replaceArguments(this.partDetail, this.templateVersion.arguments))
                )
                .subscribe(loadedUrls => {
                    this.loadHtml(this.partDetail?.processedHtml);
                    this.loadScript('call-action', this.createCallActionFunction());
                    if (this.partDetail.processedPreJavascriptSuccess) {
                        this.loadScript('pre-javascript', this.partDetail?.processedPreJavascript);
                    } else {
                        console.log('Not loading javascript because it contains unreplaced placeholders');
                        console.log(this.partDetail?.processedPreJavascript);
                    }
                    if (this.partDetail.processedPostJavascriptSuccess) {
                        eval(this.surroundWithTryCatch(this.partDetail?.processedPostJavascript));
                    } else {
                        console.log('Not loading javascript because it contains unreplaced placeholders');
                        console.log(this.partDetail?.processedPreJavascript);
                    }
                });
            this.partDetail?.javascriptUrls.forEach(javascriptUrl => {
                this.loadPreScriptFromUrl(javascriptUrl);
            });
        } else {
            from(replaceArguments(this.partDetail, this.templateVersion.arguments)).subscribe(() => {
                this.loadHtml(this.partDetail?.processedHtml);
                this.loadScript('call-action', this.createCallActionFunction());
                if (this.partDetail.processedPreJavascriptSuccess) {
                    this.loadScript('pre-javascript', this.partDetail?.processedPreJavascript);
                } else {
                    console.log('Not loading javascript because it contains unreplaced placeholders');
                    console.log(this.partDetail?.processedPreJavascript);
                }
                if (this.partDetail.processedPostJavascriptSuccess) {
                    eval(this.surroundWithTryCatch(this.partDetail?.processedPostJavascript));
                } else {
                    console.log('Not loading javascript because it contains unreplaced placeholders');
                    console.log(this.partDetail?.processedPostJavascript);
                }
            });
        }
    }

    executeOnEventAction(event: { name: string; arguments: [] }): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        templateActionArguments.push({
            name: 'EVENT',
            value: null,
            calculatedValue: event,
            subArguments: [],
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_EVENT');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    createCallActionFunction() {
        return "function callAction(event) { window['" + this.part.id + "'].actions.executeOnEventAction(event); }";
    }

    loadHtml(html: string) {
        if (!this.htmlAdded) {
            const htmlContainer = document.getElementById('html-container-' + this.part.id);
            if (htmlContainer) {
                htmlContainer.innerHTML = html;
                this.htmlAdded = true;
            }
        }
    }

    private loadScript(id: string, content: string) {
        if (content && content !== '') {
            if (!this.loadedScripts.has(id)) {
                const dynamicScript = document.createElement('script');
                dynamicScript.type = 'text/javascript';
                dynamicScript.async = false;
                dynamicScript.innerHTML = content;
                const partElement = document.getElementById('id-' + this.part.id);
                if (!!partElement) {
                    partElement.appendChild(dynamicScript);
                }
                this.loadedScripts.set(id, dynamicScript);
            } else {
                this.loadedScripts.get(id).innerHTML = content;
                const partElement = document.getElementById('id-' + this.part.id);
                if (!!partElement) {
                    partElement.appendChild(this.loadedScripts.get(id));
                }
            }
        }
    }

    loadCss(css: string) {
        const node = document.createElement('style'); // creates the script tag
        node.innerHTML = css; // sets the source (insert url in between quotes)
        // append to head of document
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    loadCssUrl(cssUrl: LinkDto) {
        const node = document.createElement('link'); // creates the script tag
        node.href = cssUrl.url; // sets the source (insert url in between quotes)
        node.type = 'text/css'; // set the script type
        node.charset = 'utf-8';
        node.rel = 'stylesheet';
        // append to head of document
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    loadPreScriptFromUrl(javascriptUrl: LinkDto) {
        const node = document.createElement('script'); // creates the script tag
        node.src = javascriptUrl.url; // sets the source (insert url in between quotes)
        node.type = javascriptUrl.module ? 'module' : 'text/javascript'; // set the script type
        node.async = javascriptUrl.async; // makes script run asynchronously
        node.charset = 'utf-8';
        node.addEventListener('load', () => {
            const loadedUrls = this.loadedPreJavascriptUrls.value;
            loadedUrls.push(javascriptUrl.url);
            this.loadedPreJavascriptUrls.next(loadedUrls);
        });
        // append to head of document
        document.getElementsByTagName('head')[0].appendChild(node);
    }
}
