import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { FormGroup } from '@angular/forms';
import { DataTableColumn, DataTablePartDetail, DataTableRow } from '../../model/datatable-part.detail';
import { DataTablePartStyle } from '../../model/datatable-part.style';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'app-datatable-part-front',
    templateUrl: './datatable-part-front.component.html',
    standalone: false,
})
export class DatatablePartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: DataTablePartDetail;
    partStyle: DataTablePartStyle;
    part: Part;
    templateVersion: TemplateVersion;
    application: ApplicationDto;
    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    @Input()
    public host = '';

    parentFormGroup: FormGroup;

    displayedColumns: string[] = [];

    dataSource: MatTableDataSource<DataTableRow>;

    private _columnClicked: string | undefined;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatTable, { static: false }) table: MatTable<any>;

    currentData: any[];

    columnsByKey: Map<string, DataTableColumn>;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.columnsByKey = new Map(this.partDetail.columns.map(column => [column.id, column]));
        this.initializeColumns();
        this.currentData = this.flattenData();
        this.dataSource = new MatTableDataSource(this.currentData);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.columnsByKey = new Map(this.partDetail.columns.map(column => [column.id, column]));
        this.initializeColumns();
        if (this.dataSource) {
            this.dataSource.data = this.flattenData();
            this.table.renderRows();
        } else {
            this.dataSource = new MatTableDataSource(this.currentData);
        }
    }

    initializeColumns() {
        this.displayedColumns = [];
        this.partDetail.columns.forEach(column => {
            if (column.id !== '_id' && column.visible) {
                this.displayedColumns.push(column.id);
            }
        });
    }

    ngAfterViewInit() {
        if (this.paginator) {
            this.dataSource.paginator = this.paginator;
        }
        if (this.sort) {
            this.dataSource.sort = this.sort;
        }
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    flattenData() {
        const transformedData = this.partDetail.rows.map(row => {
            const flattenedRow: any = {};
            flattenedRow['_code'] = row.code;

            // Flatten columnData into individual properties
            for (const key in row.columnData) {
                if (row.columnData.hasOwnProperty(key)) {
                    const columnData = row.columnData[key];
                    for (const dataKey in columnData) {
                        if (columnData.hasOwnProperty(dataKey)) {
                            flattenedRow[`${key}`] = columnData[dataKey];
                        }
                    }
                }
            }

            this.partDetail.columns.forEach(column => {
                flattenedRow[`${column.id}-arguments`] = this.mergeRowArguments(flattenedRow, column.id);
            });

            return flattenedRow;
        });
        return transformedData;
    }

    identifyColumn(index, item: DataTableColumn) {
        return item.id;
    }

    mergeRowArguments(row: { _code: string; _id?: string }, key: string) {
        if (this.columnsByKey.get(key)) {
            if (!!this.columnsByKey.get(key)?.templateId && this.columnsByKey.get(key)?.templateId != '') {
                const rowData = {
                    _code: row._code,
                };

                for (const column of this.partDetail.columns) {
                    if (row.hasOwnProperty(column.id)) {
                        rowData[column.code] = row[column.id];
                    }
                }

                return Object.assign(
                    [
                        {
                            name: 'ROW_CODE',
                            value: row._code,
                            calculatedValue: row._code,
                            subArguments: undefined,
                        },
                        {
                            name: 'ROW_DATA',
                            value: JSON.stringify(rowData),
                            calculatedValue: rowData,
                            subArguments: undefined,
                        },
                    ],
                    this.columnsByKey.get(key)?.arguments
                );
            }
        }
        return [];
    }

    onRowClicked(row: { _code: string; _id?: string }): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });

        templateActionArguments.push({
            name: 'ROW_CODE',
            value: row._code,
            calculatedValue: row._code,
            subArguments: undefined,
        });

        templateActionArguments.push({
            name: 'COLUMN_CODE',
            value: this._columnClicked,
            calculatedValue: this._columnClicked,
            subArguments: undefined,
        });

        templateActionArguments.push({
            name: 'DATA',
            value: JSON.stringify(row),
            calculatedValue: row,
            subArguments: undefined,
        });

        // Map row to rowData which has the column code's as keys instead of the column id's as keys.
        // This makes it much easier to reason with in the action.
        const rowData = {
            _code: row._code,
        };
        for (const column of this.partDetail.columns) {
            if (row.hasOwnProperty(column.id)) {
                rowData[column.code] = row[column.id];
            }
        }

        templateActionArguments.push({
            name: 'ROW_DATA',
            value: JSON.stringify(rowData),
            calculatedValue: rowData,
            subArguments: undefined,
        });

        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK_DATATABLE_ROW');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        } else {
            //LEGACY
            const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK');
            if (!!actionLinks && actionLinks.length > 0) {
                this.executeAction.emit({
                    trigger: this.part.id,
                    actionLinks: actionLinks,
                    arguments: templateActionArguments,
                });
            }
        }
    }

    onColumnClicked(column: DataTableColumn) {
        if (column) {
            this._columnClicked = column.code;
        } else {
            this._columnClicked = undefined;
        }
    }
}
