import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { RadarChartPartDetail } from '../../model/radar-chart-part.detail';
import { RadarChartPartStyle } from '../../model/radar-chart-part.style';
import { ChartTitleOptions } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-title-options.interface';
import {
    ChartRadarIndicatorOptions,
    ChartRadarOptions,
} from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-radar-options.interface';
import {
    RadarChartItemStyleOptions,
    RadarChartLineStyleOptions,
    RadarChartSeriesDataOptions,
    RadarChartSeriesOptions,
} from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/radar-chart-series-options.interface';
import { ChartLegendOptions } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/dto/chart-legend-options.interface';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { ColorV2 } from '@shared/data-access';

@Component({
    selector: 'app-radar-chart-part-front',
    templateUrl: './radar-chart-part-front.component.html',
    styleUrls: ['./radar-chart-part-front.component.scss'],
    standalone: false,
})
export class RadarChartPartFrontComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: RadarChartPartDetail;
    partStyle: RadarChartPartStyle;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    get part(): Part {
        return this._part;
    }

    set part(value: Part) {
        this._part = value;
        this._handleDetailChanged();
    }

    _part: Part;

    options: any;

    chartInstance: any;

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        setTimeout(() => {
            this._handleDetailChanged();
        }, 200);
    }

    onChartInit(e: any): void {
        this.chartInstance = e;
        setTimeout(() => {
            this._handleDetailChanged();
        }, 200);
    }

    private _handleDetailChanged(): void {
        if (!!this._part) {
            const partDetail = this._part.detail as RadarChartPartDetail;
            const partStyle = this._part.style as RadarChartPartStyle;

            const cssStyles = getComputedStyle(document.body);

            const titleStyle = this.resolveTitleStyle(cssStyles);

            const titleOptions: ChartTitleOptions = {
                text: partDetail?.title,
                textStyle: {
                    color: titleStyle.color,
                    fontStyle: titleStyle.fontStyle,
                    fontWeight: titleStyle.fontWeight,
                    fontFamily: titleStyle.fontFamily,
                    fontSize: titleStyle.fontSize,
                },
            };

            const { indicators: indicatorInfo } = partDetail;
            const radar = new ChartRadarOptions({
                indicator:
                    indicatorInfo?.values?.split(';').map(value => {
                        const valueMaxMin = value.split(':');
                        return new ChartRadarIndicatorOptions({
                            name: valueMaxMin[0].replace(' ', '\n'),
                            max: valueMaxMin.length >= 1 ? Number(valueMaxMin[1]) : null,
                        });
                    }) ?? [],
                center: partStyle.centerX && partStyle.centerY ? [partStyle.centerX, partStyle.centerY] : ['50%', '50%'],
                radius: partStyle.radius ? partStyle.radius : '80%',
                shape: partStyle.shape ? partStyle.shape : 'polygon',
                splitArea: {
                    areaStyle: {
                        color: [],
                        shadowColor: 'rgba(0, 0, 0, 0.2)',
                        shadowBlur: 10,
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: cssStyles.getPropertyValue('--label-medium-font-color'),
                    },
                },
                axisName: {
                    formatter: '【{value}】',
                    color: cssStyles.getPropertyValue('--label-medium-font-color'),
                },
            });

            const mapping =
                partDetail?.datasources?.map(sourceDetail => {
                    const sourceStyle = partStyle?.series.find(x => x.id === sourceDetail.id);
                    return {
                        detail: sourceDetail,
                        style: sourceStyle,
                    };
                }) ?? [];

            const series =
                new RadarChartSeriesOptions({
                    data: mapping.map(ref => {
                        const { detail, style } = ref;
                        return new RadarChartSeriesDataOptions({
                            name: detail.name,
                            value: detail?.points?.split(';') ?? [],
                            lineStyle: new RadarChartLineStyleOptions({ color: this.resolveColorV2(style?.itemStyle.colorV2, cssStyles) }),
                            itemStyle: new RadarChartItemStyleOptions({ color: this.resolveColorV2(style?.itemStyle.colorV2, cssStyles) }),
                            areaStyle: style?.itemStyle?.fillArea
                                ? { color: this.resolveColorV2(style?.itemStyle.areaColorV2, cssStyles) }
                                : null,
                        });
                    }),
                }) ?? [];

            const { legend: legendInfo } = partStyle;
            const legend = new ChartLegendOptions({
                show: legendInfo?.show,
                orient: legendInfo?.orient,
                left: legendInfo?.left,
                top: legendInfo?.top,
                data:
                    mapping.map(source => ({
                        name: source.detail.name,
                        itemStyle: { color: this.resolveColorV2(source.style?.itemStyle.colorV2, cssStyles) },
                        textStyle: { color: this.resolveColorV2(source.style?.itemStyle.colorV2, cssStyles) },
                    })) ?? [],
                align: 'right',
            });

            this.options = {
                ...this.options,
                title: titleOptions,
                legend,
                radar,
                series,
                tooltip: {
                    trigger: 'item',
                },
            };
            if (this.chartInstance) {
                this.chartInstance.resize({
                    animation: {
                        enabled: partStyle.animation,
                        duration: partStyle.animationDuration,
                    },
                });

                this.chartInstance.setOption(this.options, { notMerge: false });
            }
            this.changeDetectorRef.detectChanges();
        } else {
            this.options = {};
        }
    }

    private resolveColorV2(color: ColorV2 | undefined, style: CSSStyleDeclaration): string {
        if (!color) {
            return '#ffffff';
        }

        if (color.hex) {
            return color.hex.value;
        } else {
            return style.getPropertyValue(color.reference?.variable.name ?? '');
        }
    }

    private resolveTitleStyle(style: CSSStyleDeclaration): {
        color: string;
        fontStyle: string;
        fontWeight: string;
        fontFamily: string;
        fontSize: number;
    } {
        return {
            color: style.getPropertyValue('--headline-medium-font-color') ?? '#ffffff',
            fontStyle: style.getPropertyValue('--headline-medium-font-style') ?? 'normal',
            fontWeight: style.getPropertyValue('--headline-medium-font-weight') ?? 'bolder',
            fontFamily: style.getPropertyValue('--headline-medium-font-family') ?? 'sans-serif',
            fontSize: style.getPropertyValue('--headline-medium-font-size')
                ? +style.getPropertyValue('--headline-medium-font-size').replace('px', '').replace('rem', '')
                : 18,
        };
    }
}
