import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { ActionService } from '@frontoffice/data-access/template';

@Injectable()
export class Streamresults implements Method {
    constructor(private actionService: ActionService) {}

    execute(executionScope: ExecutionScope): void {
        const host = executionScope.getRequiredVariable('host');
        const otp: string = executionScope.getRequiredVariable('otp');

        this.actionService.streamActionUpdates(host, otp).subscribe(result => {
            const executionResult = JSON.parse(result.data).actionExecutionCreatedDto;
            executionResult.templateVersion.instanceIdentifier = executionScope.getRequiredVariable('template').instanceIdentifier;

            executionScope.getRequiredVariable('handleActionExecutionResult')(executionResult, {
                triggerType: 'ASYNC_WITH_FRONTEND',
                executionResultPartId: executionScope.getRequiredVariable('executionResultPartId'),
            });
        });
    }
}
