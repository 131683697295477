import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, PartDetail, TemplateVersion } from '@frontoffice/data-access/template';
import { replaceArguments, TextFieldPartDetail } from '../../model/text-field-part.detail';
import { TextFieldPartStyle } from '../../model/text-field-part.style';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { Subscription } from 'rxjs';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';
import { NgxFloatUiTriggers } from 'ngx-float-ui';
import { FormField } from '../../../form-field/form-field.component';

@Component({
    selector: 'app-text-field-part-front',
    templateUrl: './text-field-part-front.component.html',
    styleUrls: ['./text-field-part-front.component.scss'],
    standalone: false,
})
export class TextFieldPartFrontComponent extends FormField implements ComponentFront, OnInit, OnChanges, OnDestroy {
    partDetail: TextFieldPartDetail = null;
    partStyle: TextFieldPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    answerControlName: string;

    subscriptions: Subscription = new Subscription();

    gridSize: number = window.innerWidth / 100;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        override fb: FormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        // This is necessary because else the filter for the onchange subscription will fail undefined != null.
        this.partDetail.processedAnswer = null;
        this.initialize();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.change();
        // Keep this at != instead of !== because invalidMessage can be sometimes null or undefined;
        if (
            changes['part']?.previousValue?.detail?.invalidMessage != changes['part']?.currentValue?.detail?.invalidMessage ||
            changes['part']?.previousValue?.detail?.required !== changes['part']?.currentValue?.detail?.required ||
            changes['part']?.previousValue?.detail?.requiredMessage !== changes['part']?.currentValue?.detail?.requiredMessage
        ) {
            this.valueControl()?.markAsTouched({ onlySelf: true });
            this.valueControl()?.updateValueAndValidity();
        }
    }

    ngOnDestroy(): void {
        this.destroy();
        this.subscriptions.unsubscribe();
    }

    executeOnChangeAction(): void {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument?.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CHANGE');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    override getSubscriptions(): Subscription {
        return this.subscriptions;
    }

    override replaceArguments(): Promise<void> {
        return replaceArguments(this.partDetail, this.templateVersion.arguments);
    }

    override getValue(): any {
        return this.partDetail.processedAnswer;
    }

    override setValue(value: any): void {
        this.partDetail.answer = value;
        this.partDetail.processedAnswer = value;
    }

    override getPartDetail(): PartDetail {
        return this.partDetail;
    }

    override getCode(): string {
        return this.partDetail.code;
    }

    override getType(): string {
        return this.partDetail.partType;
    }

    override getIdentifier(): string | undefined {
        return this.part.instanceIdentifier;
    }

    override isRequired(): boolean {
        return this.partDetail.required;
    }

    override isEnabled(): boolean {
        return this.partDetail.enabled;
    }

    override getValidationRegex(): string | null {
        return null;
    }

    override getInvalidMessage(): string | null {
        return this.partDetail.invalidMessage;
    }

    override setInvalidMessage(invalidMessage: string | null): void {
        this.partDetail.invalidMessage = invalidMessage;
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
