import { Injectable } from '@angular/core';
import { Method } from './method';
import { ExecutionScope } from '../../../../../data-access/template/src/lib/dto/interpretion/execution-scope';
import { OnSameUrlNavigation, Router } from '@angular/router';
import { TemplateFacade } from '../../../../../data-access/template/src/lib/facade/template.facade';

@Injectable()
export class Route implements Method {
    constructor(
        private router: Router,
        private templateFacade: TemplateFacade
    ) {}
    execute(executionScope: ExecutionScope): void {
        const routeUrl = executionScope.getRequiredVariable('url');
        const force = executionScope.getRequiredVariable('force');
        const host = executionScope.getRequiredVariable('host');
        const reloadApp = executionScope.getRequiredVariable('reloadApp');
        const webComponent = executionScope.getOptionalVariable('webComponent', false);
        const onSameUrlNavigation: Extract<OnSameUrlNavigation, 'reload'> | undefined = force ? 'reload' : undefined;
        if (routeUrl) {
            if (webComponent) {
                const templateId = executionScope.getRequiredVariable('templateId');
                const host = executionScope.getRequiredVariable('host');
                const _arguments = executionScope.getRequiredVariable('arguments');
                this.templateFacade.fetchExternalAppTemplate(templateId, _arguments, host);
            } else {
                if (reloadApp) {
                    window.document.location = window.document.location.protocol + '//' + window.document.location.host + routeUrl;
                } else {
                    this.router.navigateByUrl(`${routeUrl}`, {
                        onSameUrlNavigation,
                    });
                }
            }
        }
    }
}
