<div *ngIf="partDetail">
    <button
        *ngIf="part.sizeX > 2"
        [id]="'id-' + part.id"
        mat-fab
        class="part-content icon-button"
        (click)="onClick()"
        color="primary"
        [matTooltip]="partDetail.processedTooltip"
        [disabled]="!partDetail.enabled"
        extended>
        <mat-icon>{{ partDetail.processedIcon }}</mat-icon>
    </button>
    <button
        *ngIf="part.sizeX <= 2"
        [id]="'id-' + part.id"
        mat-mini-fab
        class="part-content icon-button"
        (click)="onClick()"
        color="primary"
        [matTooltip]="partDetail.processedTooltip"
        [disabled]="!partDetail.enabled"
        extended>
        <mat-icon>{{ partDetail.processedIcon }}</mat-icon>
    </button>
</div>
