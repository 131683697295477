import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable, Subscription } from 'rxjs';
import { TemplateVersion } from '@frontoffice/data-access/template';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { TemplateArgument } from '../../../../../libs/frontoffice/data-access/template/src/lib/models/template-argument.model';
import { frontofficeEnvironment } from '@shared/environment';
import { select, Store } from '@ngrx/store';
import { selectApplication } from '../store/application/application.selectors';
import { AppState } from '../store/app.state';
import { ApplicationDto } from '../dto/application.dto.interface';
import { TemplateFacade } from '../../../../../libs/frontoffice/data-access/template/src/lib/facade/template.facade';
import { PartActionLink } from '../shared-template/model/part-action-link.model';

@Component({
    selector: 'app-templateversion-dialog',
    templateUrl: './templateversion-dialog.component.html',
    styleUrls: ['./templateversion-dialog.component.scss'],
    standalone: false,
})
export class TemplateversionDialogComponent implements OnInit, OnDestroy {
    public path: string = '';

    public host: string = '';

    template$: Observable<TemplateVersion>;

    application$: Observable<ApplicationDto> = this.store.pipe(select(selectApplication));

    subscriptions: Subscription = new Subscription();

    parentFormGroup: FormGroup;

    templateId: string;

    parentTemplateInstanceIdentifier: string;

    templateArguments: TemplateArgument[];

    storeApplication$ = this.store.pipe(select(selectApplication));

    dialogRefs: MatDialogRef<any>[] = [];

    constructor(
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private templateFacade: TemplateFacade,
        public fb: FormBuilder,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store: Store<AppState>
    ) {
        this.templateId = data.templateId;
        this.templateArguments = data.templateArguments;
        this.parentTemplateInstanceIdentifier = data.parentTemplateInstanceIdentifier;
        this.dialogRefs = data.dialogRefs;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.template$ = this.templateFacade
            .fetchTemplateById(
                this.templateId,
                this.templateArguments,
                'dialog-' + this.parentTemplateInstanceIdentifier + '-' + this.templateId
            )
            .pipe(
                map(template => {
                    // Not sure that this can always be OK???
                    template.arguments = this.templateArguments;
                    return template;
                })
            );
        this.subscriptions.add(
            this.router.events
                .pipe(
                    filter(e => e instanceof NavigationEnd),
                    switchMap((event, index) => {
                        return this.templateFacade.fetchBaseTemplateResponse(this.templateId).pipe(take(1));
                    }),
                    filter(
                        templateVersionResponse =>
                            !!templateVersionResponse &&
                            !!templateVersionResponse.onEntryActions &&
                            templateVersionResponse.onEntryActions.length > 0
                    ),
                    map(templateVersionResponse => {
                        // Not sure that this can always be OK???
                        templateVersionResponse.templateVersion.arguments = this.templateArguments;
                        return templateVersionResponse;
                    }),
                    tap(templateVersionResponse => {
                        return this.templateFacade.executeOnEnterAction(templateVersionResponse);
                    })
                )
                .subscribe()
        );
        this.parentFormGroup = this.fb.group({});
        this.parentFormGroup.addControl('parent', this.fb.group({}));
    }

    initializePage(): void {
        this.path = this.router.url;
        this.host = this.getHost(this.document.location);
    }

    initTemplate(): void {
        /*this.subscriptions.add(
            this.storeApplication$
                .pipe(
                    switchMap(application => {
                        this.application$.next(application);
                        return this.templateFacade
                            .loadTemplateById(
                                this.templateId,
                                application.applicationId,
                                application.companyId,
                                this.templateArguments,
                                this.route.snapshot.queryParamMap,
                                application,
                                this.dialogRefs
                            )
                            .pipe(map(templateVersionResponse => {}));
                    })
                )
                .subscribe()
        );*/
    }

    catchTemplateError(error, caught) {}

    createFormResults(parentFormGroup: FormGroup) {
        const formValues = parentFormGroup.getRawValue();
        //const partsById = TemplateVersion.createPartsById(this.template$.value.parts);
        //return this.createFormResultList(partsById, formValues);
        return null;
    }

    private getHost(location: Location) {
        const host = location.hostname;
        if (host !== 'localhost') {
            if (host.indexOf('app.nocode-x.com') > -1) {
                return 'https://' + host.replace('app.nocode-x.com', 'back.nocode-x.com');
            } else {
                if (host.startsWith('dev-')) {
                    return 'https://' + 'dev-back-' + host.replace('dev-', '');
                } else if (host.startsWith('test-')) {
                    return 'https://' + 'test-back-' + +host.replace('test-', '');
                } else if (host.startsWith('accept-')) {
                    return 'https://' + 'accept-back-' + host.replace('accept-', '');
                } else {
                    return 'https://' + 'back-' + host;
                }
            }
        }
        return frontofficeEnvironment.localTestApp ? frontofficeEnvironment.localTestApp : null;
    }

    onExecuteAction($event: {
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
        templateInstanceIdentifier?: string;
        executionResultPartId?: string;
    }): void {
        const path = location.pathname;
        $event.actionLinks.forEach(actionLink => {
            this.templateFacade.executeAction(
                $event.trigger,
                actionLink.type,
                [actionLink.actionId],
                $event.templateInstanceIdentifier
                    ? $event.templateInstanceIdentifier
                    : 'dialog-' + this.parentTemplateInstanceIdentifier + '-' + this.templateId,
                $event.arguments,
                $event.executionResultPartId,
                false,
                this.parentFormGroup,
                null,
                undefined,
                false
            );
        });
    }
}
