<div
    *ngIf="partDetail && parentFormGroup && this.answerControlName"
    [formGroup]="parentFormGroup"
    [id]="'id-' + part.id"
    class="part-content radiobutton-field">
    <div class="question" data-name="form-component-question">
        <span
            >{{ partDetail.processedLabel }}
            <ng-container *ngIf="partDetail.processedLabel && partDetail.processedLabel !== '' && partDetail.required">*</ng-container
            ><mat-icon
                dataFormat-name="form-component-description"
                color="primary"
                class="help-icon"
                *ngIf="partDetail.help"
                [showTrigger]="NgxFloatUiTriggers.hover"
                appendTo="body"
                [floatUi]="questionInformationPopover"
                >help</mat-icon
            >
        </span>
        <float-ui-content #questionInformationPopover>
            <div class="popover">
                <div [innerHTML]="partDetail.processedHelpInformation"></div>
            </div>
        </float-ui-content>
    </div>
    <mat-error
        *ngIf="
            parentFormGroup.controls[this.answerControlName].touched &&
            parentFormGroup.controls[this.answerControlName].invalid &&
            partDetail.required &&
            parentFormGroup.controls[this.answerControlName].hasError('required')
        "
        >{{ partDetail.requiredMessage }}
    </mat-error>

    <mat-grid-list
        rowHeight="40px"
        [cols]="partStyle?.normalStyle?.columnsStyle?.columnAmount ? partStyle.normalStyle.columnsStyle.columnAmount : 1"
        [gutterSize]="partStyle?.normalStyle?.columnsStyle?.columnGutter ? partStyle?.normalStyle?.columnsStyle?.columnGutter : '10px'">
        <mat-radio-group [formControlName]="this.answerControlName" [required]="this.partDetail.required">
            <ng-container *ngFor="let choice of this.partDetail.choices">
                <mat-grid-tile>
                    <mat-radio-button
                        class="choice"
                        color="primary"
                        [checked]="parentFormGroup.get(this.answerControlName)?.value === choice.code"
                        [value]="choice.code"
                        [disabled]="!this.partDetail.enabled"
                        >{{ choice.processedLabel }}
                        <mat-icon
                            class="help-icon"
                            color="primary"
                            *ngIf="choice.help"
                            [showTrigger]="NgxFloatUiTriggers.hover"
                            appendTo="body"
                            [floatUi]="choiceInformationPopover"
                            >help
                        </mat-icon>
                        <float-ui-content #choiceInformationPopover>
                            <div class="popover">
                                <div [innerHTML]="choice.processedHelpInformation"></div>
                            </div>
                        </float-ui-content>
                    </mat-radio-button>
                </mat-grid-tile>
            </ng-container>
        </mat-radio-group>
    </mat-grid-list>
</div>
