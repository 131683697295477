import 'reflect-metadata';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'codex-progress',
    styleUrls: ['./progress.component.scss'],
    template: `<div
        style="border-radius: 9999px; border-width: 1px; border-color:#334155; position:fixed; bottom: 0.25rem; right: 0.25rem; z-index: 10000; background-color: #020617; height: 70px; width: 70px;">
        <video autoplay loop muted playsinline style="width: 70px; height: 70px">
            <source src="/src/images/theme/loading.webm" type="video/webm" />
        </video>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ProgressComponent {}
