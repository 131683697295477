import { Component, HostListener, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { combineLatest, filter, Observable, of, Subscription } from 'rxjs';
import { TemplateVersion } from '@frontoffice/data-access/template';
import { switchMap } from 'rxjs/operators';
import { ApplicationDto } from '../../../dto/application.dto.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../shared-template/model/part-action-link.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressService } from '../../../shared-template/components/progress/progress.service';
import { frontofficeEnvironment } from '@shared/environment';
import { TemplateFacade } from '../../../../../../../libs/frontoffice/data-access/template/src/lib/facade/template.facade';
import { select, Store } from '@ngrx/store';
import { selectApplication } from '../../../store/application/application.selectors';
import { AppState } from '../../../store/app.state';
import { TemplateArgument } from '../../../../../../../libs/frontoffice/data-access/template/src/lib/models/template-argument.model';

declare var jQuery: any;

@Component({
    selector: 'app-templateversion',
    templateUrl: './templateversion.component.html',
    styleUrls: ['./templateversion.component.scss'],
    standalone: false,
})
export class TemplateversionComponent implements OnInit, OnDestroy, OnChanges {
    loadingBusys$: Observable<boolean> = this.progressService.isLoading;

    public path = '';

    public host = '';

    subscriptions: Subscription = new Subscription();

    showDeveloperTools = false;

    parentFormGroup: FormGroup;

    dialogRefs: MatDialogRef<any>[] = [];

    showDefaultNotFound: boolean = false;
    showDefaultUsageExceeded: boolean = false;
    showDefaultForbidden: boolean = false;
    showDefaultError: boolean = false;

    application$: Observable<ApplicationDto> = this.store.pipe(select(selectApplication));
    template$: Observable<TemplateVersion> = combineLatest([
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)),
        this.application$,
    ]).pipe(
        filter(([event, application]) => !!application),
        switchMap(([event]) => {
            if ((event as NavigationEnd).url.endsWith('/status/nocodex-default-not-found')) {
                this.showDefaultNotFound = true;
                return of(null);
            } else if ((event as NavigationEnd).url.endsWith('/status/nocodex-default-usage-exceeded')) {
                this.showDefaultUsageExceeded = true;
                return of(null);
            } else if ((event as NavigationEnd).url.endsWith('/status/nocodex-default-forbidden')) {
                this.showDefaultForbidden = true;
                return of(null);
            } else if ((event as NavigationEnd).url.endsWith('/status/nocodex-default-error')) {
                this.showDefaultError = true;
                return of(null);
            } else {
                this.showDefaultError = false;
                this.showDefaultForbidden = false;
                this.showDefaultUsageExceeded = false;
                this.showDefaultNotFound = false;
                return this.templateFacade.fetchTemplateByPath();
            }
        })
    );

    @HostListener('window:keydown.Alt.l', ['$event'])
    onKeyDownAltL(e): void {
        if (
            this.host.startsWith('https://dev-') ||
            this.host.startsWith('https://localhost') ||
            this.host.startsWith('https://test-') ||
            this.host.startsWith('https://accept-')
        ) {
            this.showDeveloperTools = !this.showDeveloperTools;
        }
    }

    constructor(
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private templateFacade: TemplateFacade,
        public fb: FormBuilder,
        public dialog: MatDialog,
        private progressService: ProgressService,
        private store: Store<AppState>
    ) {
        this.host = this.getHost(this.document.location);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {}

    ngOnInit(): void {
        this.parentFormGroup = this.fb.group({});
        this.parentFormGroup.addControl('parent', this.fb.group({}));
    }

    initializeMetaData(template: TemplateVersion): void {
        if (template?.templateDto?.id) {
            jQuery('body').prop('id', 'id-' + template.templateDto.id);
            jQuery('head title').text(template.metaTitle);
            jQuery('meta[name="description"]').remove();
            jQuery('meta[property="og:title"]').remove();
            jQuery('meta[property="og:description"]').remove();
            jQuery('head').append('<meta name="description" content="' + template.metaDescription + '">');
            jQuery('head').append('<meta property="og:title" content="' + template.metaTitle + '">');
            jQuery('head').append('<meta property="og:description" content="' + template.metaDescription + '">');
        }
    }

    onExecuteAction($event: {
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
        templateInstanceIdentifier?: string;
        executionResultPartId?: string;
    }): void {
        const path = location.pathname;
        $event.actionLinks.forEach(actionLink => {
            this.templateFacade.executeAction(
                $event.trigger,
                actionLink.type,
                [actionLink.actionId],
                $event.templateInstanceIdentifier ? $event.templateInstanceIdentifier : path.slice(1).replace(new RegExp('/', 'g'), '-'),
                $event.arguments,
                $event.executionResultPartId,
                !$event.templateInstanceIdentifier,
                this.parentFormGroup,
                path.slice(1).replace(new RegExp('/', 'g'), '-'),
                undefined,
                false
            );
        });
    }

    private getHost(location: Location) {
        const host = location.hostname;
        if (host !== 'localhost') {
            if (host.indexOf('app.nocode-x.com') > -1) {
                return 'https://' + host.replace('app.nocode-x.com', 'back.nocode-x.com');
            } else {
                if (host.startsWith('dev-')) {
                    return 'https://' + 'dev-back-' + host.replace('dev-', '');
                } else if (host.startsWith('test-')) {
                    return 'https://' + 'test-back-' + +host.replace('test-', '');
                } else if (host.startsWith('accept-')) {
                    return 'https://' + 'accept-back-' + host.replace('accept-', '');
                } else {
                    return 'https://' + 'back-' + host;
                }
            }
        }
        return frontofficeEnvironment.localTestApp ? frontofficeEnvironment.localTestApp : null;
    }
}
