import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { PlanePartDetail } from '../../model/plane-part.detail';
import { PlanePartStyle } from '../../model/plane-part.style';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import {
    getClosestPartPositioning,
    PartPositioning,
} from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-positioning.dto';
import { BehaviorSubject, debounceTime, Subscription } from 'rxjs';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

declare let jQuery: any;

@Component({
    selector: 'app-plane-part-front',
    templateUrl: './plane-part-front.component.html',
    styleUrls: ['./plane-part-front.component.scss'],
    standalone: false,
})
export class PlanePartFrontComponent implements ComponentFront, OnInit, OnChanges, AfterViewInit, OnDestroy {
    partDetail: PlanePartDetail = null;
    partStyle: PlanePartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{
        trigger: string;
        actionLinks: PartActionLink[];
        arguments: TemplateArgument[];
    }>;

    parentFormGroup: FormGroup;

    host = '';

    subscriptions: Subscription = new Subscription();

    resizeObserver: ResizeObserver;

    mutationObserver: MutationObserver;

    private resizePart: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public window: Window
    ) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.resizePart.pipe(debounceTime(50)).subscribe(() => {
                this.adjustHeightOfSubContainer();
            })
        );
    }

    ngOnChanges() {}

    ngOnDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.initResizeObservers();
    }

    initResizeObservers() {
        const element = document.querySelector('#id-' + this.part?.id);
        if (element) {
            this.resizeObserver = new ResizeObserver(entries => {
                this.resizePart.next(this.resizePart.value + 1);
            });
            this.resizeObserver.observe(element);

            this.mutationObserver = new MutationObserver(() => {
                this.resizePart.next(this.resizePart.value + 1);
            });
            this.mutationObserver.observe(element, {
                childList: true,
                attributes: false,
                subtree: true,
            });
        }
    }

    setParentHeight(parent: any, value: string) {
        if (parent.css('height') !== value) {
            parent.height(value);
        }
    }

    setParentWidth(parent: any, value: string) {
        if (parent.css('width') !== value) {
            parent.width(value);
        }
    }

    adjustHeightOfSubContainer(): void {
        const parent = jQuery('#id-' + this.part?.id);
        const partPositioning: PartPositioning = getClosestPartPositioning(window.innerWidth, window.innerHeight, this.part.positions);
        let listItems = null;
        if (partPositioning.sizeYUnit === 'fit-content' || partPositioning.sizeXUnit === 'fit-content') {
            listItems = jQuery('#id-' + this.part.id + ' > part');
        }
        if (partPositioning.sizeYUnit === 'fit-content') {
            let highestHeight = 0;
            for (let i = 0; i < listItems.length; i++) {
                const content = listItems.eq(i);
                if (content.height() + content.position().top > highestHeight) {
                    highestHeight = content.height() + content.position().top;
                }
            }
            this.setParentHeight(parent, Math.round(highestHeight) + 'px');
        }

        if (partPositioning.sizeXUnit === 'fit-content') {
            let highestWidth = 0;
            for (let i = 0; i < listItems.length; i++) {
                const content = listItems.eq(i);
                if (content.width() + content.position().left > highestWidth) {
                    highestWidth = content.width() + content.position().left;
                }
            }
            this.setParentWidth(parent, Math.round(highestWidth) + 'px');
        }
    }

    identifyPart(index, item) {
        // Adding templateID to identify this part is a very hacky way to make sure template parts should be refreshed if
        // They have the same selectorId but a different template configured.
        // This can happen when a user copies a page & then changes the template in the copied page.
        // Without templateID in this return value, hopping from the copied page to the original page & back will not result in changing the content of
        // the template component.
        return item.selectorId + item.detail['templateId'];
    }

    onClick() {
        const templateActionArguments = this.templateVersion?.arguments?.map(templateArgument => {
            return {
                name: templateArgument.name,
                value: templateArgument.value,
                calculatedValue: templateArgument.calculatedValue,
                subArguments: templateArgument.subArguments,
            };
        });
        const actionLinks: PartActionLink[] = Part.getActionLinkOfType(this.part, 'ON_CLICK');
        if (!!actionLinks && actionLinks.length > 0) {
            this.executeAction.emit({
                trigger: this.part.id,
                actionLinks: actionLinks,
                arguments: templateActionArguments,
            });
        }
    }

    generateStyle() {
        let style = '';
        if (this.partStyle.backgroundStyle) {
            if (this.partStyle.backgroundStyle.backgroundColor)
                style += 'background-color:' + this.partStyle.backgroundStyle.backgroundColor + ';';
        }
        return style;
    }
}
