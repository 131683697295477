import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentFront } from '../../../../../interface/component.front';
import { DomSanitizer } from '@angular/platform-browser';
import { replaceArguments, TextPartDetail } from '../../model/text-part.detail';
import { Part, TemplateVersion } from '@frontoffice/data-access/template';
import { TextPartStyle } from '../../model/text-part.style';
import { ApplicationDto } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/dto/application.dto.interface';
import { FormGroup } from '@angular/forms';
import { PartActionLink } from '../../../../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/model/part-action-link.model';
import { TemplateArgument } from '../../../../../../../../../../frontoffice/data-access/template/src/lib/models/template-argument.model';

@Component({
    selector: 'textpartdetail',
    templateUrl: './textpartdetail.component.html',
    styleUrls: ['./textpartdetail.component.scss'],
    standalone: false,
})
export class TextpartdetailComponent implements ComponentFront, OnInit, OnChanges {
    partDetail: TextPartDetail = null;
    partStyle: TextPartStyle = null;

    part: Part = null;

    templateVersion: TemplateVersion;

    application: ApplicationDto;

    executeAction: EventEmitter<{ trigger: string; actionLinks: PartActionLink[]; arguments: TemplateArgument[] }>;

    parentFormGroup: FormGroup;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        replaceArguments(this.partDetail, this.templateVersion.arguments, this.sanitizer);
    }

    ngOnChanges(changes: SimpleChanges): void {
        replaceArguments(this.partDetail, this.templateVersion.arguments, this.sanitizer);
    }
}
